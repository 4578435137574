import React from "react";
export default function Home() {
    return (
        <div className="banner">
            <div className="content">
                Welcome to CRACKTEK , a Javascript and React interview guide
            </div>
        </div>
        
    )
}
